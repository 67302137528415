<template>
  <div>
    <v-card :loading="true">
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text> Loading ... </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoadingCard",
  props: ["title"],
};
</script>
