<template>
  <div>
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("Sales") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Name") }}</th>
                <th class="text-left">{{ $t("Sales") }}</th>
                <th class="text-left">{{ $t("TotalSales") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ item.amount }}</td>
                <td>EUR {{ item.priceGross }}</td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>
                  <span style="border-bottom: double 3px #000000"
                    >EUR {{ priceTotal }}</span
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PerProductTitle", // name of the component
  props: {
    data: {
      type: Object,
      required: true,
    },
    priceTotal: {
      type: Number,
      required: true,
    },
  },
};
</script>
