<template>
  <div>
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("SalesPerMonth") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <Bar
          v-if="dataProcessed"
          :chartData="chartData"
          :chartLabels="chartLabels"
          :backgroundColor="backgroundColor"
          :chartLabel="chartLabel"
          :height="200"
          :key="componentKey"
        /><!-- wenn daten für chart aufbereitet wurden -->

        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="5"
          circle
          light
          @input="switchPage($event)"
          class="mb-4"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Bar from "@/components/charts/Bar";
export default {
  name: "PerYearMonth",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: {
    Bar,
  },

  data() {
    return {
      dataProcessed: false,
      chartData: [],
      chartLabels: [],
      splittedArray: [],
      backgroundColor: null,
      chartLabel: this.$t("SalesPerMonth"),
      pages: 0,
      page: 1,
      pagesArray: [],
      componentKey: 0,
    };
  },

  created: function () {
    // Teile this.data in ein Array von Unterarrays mit maximal 5 Elementen
    const entries = Object.entries(this.data); // Konvertiere das Objekt in ein Array von Schlüssel-Wert-Paaren
    const chunkSize = 5; // Maximale Anzahl von Elementen pro Unterarray
    this.splittedArray = [];

    for (let i = 0; i < entries.length; i += chunkSize) {
      const chunk = entries.slice(i, i + chunkSize); // Nimm einen Abschnitt von `chunkSize` Elementen
      const chunkObject = Object.fromEntries(chunk); // Konvertiere den Abschnitt zurück in ein Objekt
      this.splittedArray.push(chunkObject); // Füge das Objekt dem Ergebnis-Array hinzu
    }

    this.pages = this.splittedArray.length; // Anzahl der Seiten

    // create array with available pages for the select component
    this.pagesArray = Array.from({ length: this.pages }, (v, k) => k + 1);

    this.processDataForChart(0);
  },

  methods: {
    switchPage(page) {
      const index = page - 1;
      this.chartData = [];
      this.chartLabels = [];
      this.dataProcessed = false;
      this.processDataForChart(index);
    },

    processDataForChart(index) {
      const data = this.splittedArray[index];
      console.log(data);

      /* Aufbereiten der Daten für die Verwendung im Chart */
      //const dataArr = Object.values(this.data);
      Object.keys(data).forEach((key) => {
        this.chartLabels.push(key);
        this.chartData.push(data[key].priceTotal);
      });
      this.backgroundColor = "rgba(124,174,43,.5)";
      this.dataProcessed = true;
      this.componentKey += 1;
    },
  },
};
</script>
