<template>
  <v-row v-if="!fetchingData" class="mb-6">
    <v-col cols="6">
      <PerProductTitle
        :data="data.ordersStats.perProductTitle"
        :priceTotal="data.ordersStats.priceTotal"
      />
    </v-col>
    <v-col cols="6">
      <PerYearMonth :data="data.ordersStats.perYearMonth" />
    </v-col>
  </v-row>

  <v-row v-else class="mb-6">
    <v-col cols="6">
      <LoadingCard :title="$t('SalesPerMonth')" />
    </v-col>
    <v-col cols="6">
      <LoadingCard :title="$t('Sales')" />
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PerProductTitle from "@/components/addons/shops/stats/PerProductTitle";
import PerYearMonth from "@/components/addons/shops/stats/PerYearMonth";
import LoadingCard from "@/components/ui/LoadingCard";

export default {
  name: "SaleStatistics",

  components: {
    PerProductTitle,
    PerYearMonth,
    LoadingCard,
  },

  data() {
    return {
      fetchingData: false,
      dataProcessed: false,
      chartData: [],
      chartLabels: [this.$t("Complete"), this.$t("invoice")],
      chartColors: ["#7CAF2A", "#C8E6C9"],
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons/shop/sales`
      );
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
      }
    },
  },
  mixins: [manageApi],
};
</script>
